/* BTN */
.btn{
    border-radius: 4px;
    padding: 4px 12px;
    font-size: 16px;
    font-weight: bold;
    font-family: inherit;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: 1px solid;
}
.btn.simple{
    border-color: rgba(var(--primaryColor-rgb), 0.8);
    fill: rgba(var(--primaryColor-rgb), 0.8);
    color: rgba(var(--primaryColor-rgb), 0.8);
}
.btn.red{
    border-color: red;
    fill: red;
    color: red;
}
.btn.success{
    border-color: rgb(40, 167, 69);
    fill: rgb(40, 167, 69);
    color: rgb(40, 167, 69);
}

.btn.dark{
    border-color: rgb(17, 24, 28);
    fill: rgb(17, 24, 28);
    color: rgb(17, 24, 28);
}

/* BTN HOVER */


.btn:hover{
    color: var(--containerColor);
    fill: var(--containerColor);
}
.btn.simple:hover{
    background-image: linear-gradient(to bottom right, rgba(var(--primaryColor-rgb), 1),rgba(var(--primaryColor-rgb), 0.6))
}
.btn.red:hover{
    background-image: linear-gradient(to bottom right, rgba(255, 0, 0, 1), rgba(255, 0, 0, 0.6))
}
.btn.success:hover{
    background-image: linear-gradient(to bottom right, rgba(40, 167, 69, 1), rgba(40, 167, 69, 0.8))
}
.btn.dark:hover{
    background-image: linear-gradient(to bottom right, rgba(17, 24, 28, 1), rgba(17, 24, 28, 0.6))
}

/* BTN OUTLINE */


.btn.outline{
    color: var(--containerColor);
    fill: var(--containerColor);
}
.btn.simple.outline{
    background-image: linear-gradient(to bottom right, rgba(var(--primaryColor-rgb), 1),rgba(var(--primaryColor-rgb), 0.6))
}
.btn.red.outline{
    background-image: linear-gradient(to bottom right, rgba(255, 0, 0, 1), rgba(255, 0, 0, 0.6))
}
.btn.success.outline{
    background-image: linear-gradient(to bottom right, rgba(40, 167, 69, 1), rgba(40, 167, 69, 0.8))
}
.btn.dark.outline{
    background-image: linear-gradient(to bottom right, rgba(17, 24, 28, 1), rgba(17, 24, 28, 0.6))
}


/* BTN OUTLINE HOVER */

.btn.simple.outline:hover{
    background-image: linear-gradient(to bottom right, rgba(var(--primaryColor-rgb), 1),rgba(var(--primaryColor-rgb), 0.8))
}
.btn.red.outline:hover{
    background-image: linear-gradient(to bottom right, rgba(255, 0, 0, 1), rgba(255, 0, 0, 0.8))
}
.btn.success.outline:hover{
    background-image: linear-gradient(to bottom right, rgba(40, 167, 69, 1), rgba(40, 167, 69, 0.9))
}
.btn.dark.outline:hover{
    background-image: linear-gradient(to bottom right, rgba(17, 24, 28, 1), rgba(17, 24, 28, 0.8))
}
