.container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    flex: 1;
    flex-shrink: 1;
    /* overflow-x: hidden; */
}
.content{
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}