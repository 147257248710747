
.header{
    background-color: var(--containerColor);
    color: var(--primaryColor);
    padding: 12px 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    position: relative;
}

