.user-paramters{
    position: absolute;
    width: 180px;
    top: 56px;
    color: var(--primaryColor);
}

.user-paramters> :hover,
.user-paramters> :active{
    background-color: var(--backgroundColor);
}