.dialog {
  animation: slide-up 0.3s ease-out; /* Animation for dialog slide-up */
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1008; /* Ensure it is above other content */
  animation: fade-in 0.3s ease-out; /* Animation for backdrop fade-in */
}
/* Keyframes for sliding the dialog up from the bottom */
@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Keyframes for sliding the dialog down to the bottom */
@keyframes slide-down {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

/* Keyframes for fading in the backdrop */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyframes for fading out the backdrop */
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Applying slide-down animation when closing */
.dialog-slide-down {
  animation: slide-down 0.3s ease-out forwards;
}

/* Applying fade-out animation when closing the backdrop */
.backdrop-fade-out {
  animation: fade-out 0.3s ease-out forwards;
}
